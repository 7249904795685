@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// @import "materia_variables";

$family-sans-serif: "Roboto Condensed", "system-ui", "system-font", Helvetica, Arial, sans-serif;
$family-monospace: "Roboto Mono", monospace;

$body-size: var(--body-font-size);

$size-1: 4.8rem;
$size-2: 4rem;
$size-3: 3.2rem;
$size-4: 2.4rem;
$size-5: 2rem;
$size-6: 1.6rem;
$size-7: 1.2rem;

$block-spacing: 2.4rem;

$primary: #008cba;
$primary-invert: findColorInvert($primary);
$link: $primary;

$column-gap: 0.6rem;

$box-background-color: rgba( 255,255,255, 0.85);
$box-shadow: 0.24rem 0.24rem 1.2rem rgba(0, 0, 0, 0.5);
$box-padding: 2.4rem;

$table-background-color: transparent;
$table-striped-row-even-background-color: rgba(0,0,0,0.25);

@import "~bulma/sass/utilities/derived-variables";

$addColors: (
  "primary": (
    $primary,
    $primary-invert
  )
);
$colors: map-merge($colors, $addColors);
