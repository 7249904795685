
@import "@/styles/_variables.scss";

.sidebar {
  @media print {
    display: none !important;
  }
  z-index: 4000;
  display: inline-block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;

  .mainbar {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5000;
    height: 100vh;

    background-color: #26363b;
    color: #eee;
    box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);

    header {
      background-color: $primary;
      color: #eee;

      border-bottom: 1px solid #eee;
      .nav-icon {
        margin: 0 auto;
      }
      margin-bottom: 1.2rem;
    }

    .utilities {
      border-top: 1px solid #eee;
      .nav-icon {
        margin: 0 auto;
      }
      margin-top: 1.2rem;
    }

    .favorites {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .nav-icon {
      flex: 0 0 auto;
      font-size: 2.4rem;
      line-height: 3.6rem;
      text-align: center;
      display: block;
      width: 6.4rem;
      height: 6.4rem;
      padding: 1.2rem;
      margin: 0.6rem auto;
      overflow: hidden;
      &.router-link-active {
        padding-left: 1rem;
        border-left: 0.2rem solid #eee;
      }
    }

    a,
    a:visited,
    a:hover {
      color: #eee;
      &.router-link-exact-active {
        color: #fff;
        text-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);
      }
    }

    a:hover {
      text-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.5);
    }
  }

  .subbar {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 24rem;
    left: calc(-24rem - 100%);
    z-index: 4000;
    height: 100vh;
    transition: left 0.4s;

    background: #eee;

    padding: 1.2rem;
    font-size: 1.6rem;

    &.visible {
      left: 100%;
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.75);
    }

    .options {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .settings {
        flex: 1 1 auto;
      }
    }

    .menu {
      font-size: 1.6rem;
      ::v-deep a {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .icon {
          margin-right: 0.6rem;
        }
      }
    }
  }
}
