
main {
  display: flex;
  #main-sidebar {
    flex: 0 0 auto;
  }
  #main-content {
    flex: 1 1 auto;
    min-height: 100vh;
  }
}
